import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../store/actions/authActions";
import { withRouter } from "react-router-dom";
import classes from "./authStyles.module.scss";
import { setPageTitle } from "../../utils";

const SignIn = ({ history }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  setPageTitle("Login");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    setDisable(true);

    dispatch(login({ username, password }, history));
  };


  useEffect(() => {
    if (!auth.loading) {
      setDisable(false);
    }
  }, [auth]);

  return (
    <div className={classes.authContainer}>
      <div className={classes.authContainer_rightSide}>
        <h2 className={classes.authContainer_rightSide__header}>
          Login
        </h2>
        <form onSubmit={submitHandler}>
          <div className={classes.inputContainer}>
            <label className={classes.inputContainer_label}>
              Gebruikersnaam
            </label>
            <input
              type="text"
              className={classes.inputContainer_input}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required={true}
            />
          </div>
          <div className={classes.inputContainer}>
            <label className={classes.inputContainer_label}>
              Uw Wachtwoord
            </label>
            <input
              type="password"
              className={classes.inputContainer_input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
          </div>
          <button
            className={classes.authContainer_submitBtn}
            disabled={disable}
            type="submit"
          >
            {auth.loading ? "Please wait..." : "Login"}
          </button>
          {auth.loginErrors?.error && (
            <p className={classes.errorMessage}>
              {auth.loginErrors.error}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default withRouter(SignIn);
