import React, { useEffect } from 'react'
import { setPageTitle } from '../../utils'
import './styles.css'
import { useSelector, useDispatch } from 'react-redux'
import { loadBoard } from '../../store/actions/boardActions'
import FullScreenLoading from '../../components/Loader/FullScreenLoading'
import Board from './Board'


const BoardPage = ({ match, history }) => {
  const dispatch = useDispatch()
  const board = useSelector(state => state.board)
  const auth = useSelector(state => state.auth)
  const boardId = match.params.boardId

  useEffect(() => {
    dispatch(loadBoard(boardId))
    setPageTitle("Board")

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {board?.board?.loading ? (
        <FullScreenLoading />
      ) : (
        <>
          {board?.board?.errors.length ? (
            <div className="errorMessage">
              Failed to load the board's data. Please try again later!
            </div>
          ) : (
            <>
              {!board.board?.data?.users?.includes(auth.user?.id) ? (
                history.push("/access-denied")
              ) : (
                <Board boardId={board?.board?.data?.board_id} />
              )}
            </>
          )}
        </>
      )}

    </>
  )

}


export default BoardPage