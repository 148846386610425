// auth action types
// user
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
export const AUTH_LOADING = "AUTH_LOADING"; // for all kind of auth stuffs (login, register, reset password bla bla bla)
export const USER_INFO_LOADED = "USER_INFO_LOADED";
export const USER_INFO_LOAD_ERROR = "USER_INFO_LOAD_ERROR";


// board
export const BOARDS_LOADING = 'BOARDS_LOADING'
export const BOARDS_LOADED = 'BOARDS_LOADED'
export const BOARDS_LOAD_ERRORS = 'BOARDS_LOAD_ERRORS'
export const SINGLE_BOARD_LOADING = 'SINGLE_BOARD_LOADING'
export const SINGLE_BOARD_LOADED = 'SINGLE_BOARD_LOADED'
export const SINGLE_BOARD_LOAD_ERROR = 'SINGLE_BOARD_LOAD_ERROR'
