import { getHeaders } from "../../utils";
import axios from "../../utils/axios";
import * as Types from "./actionTypes";

export const loadBoards = () => (dispatch) => {
  dispatch({ type: Types.BOARDS_LOADING, payload: true });
  axios
    .get("/boards/list/", { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.BOARDS_LOADED, payload: res.data });
      dispatch({ type: Types.BOARDS_LOADING, payload: false });
    })
    .catch((error) => {
      dispatch({
        type: Types.BOARDS_LOAD_ERRORS,
        payload: error?.response?.data || {},
      });

      dispatch({ type: Types.BOARDS_LOADING, payload: false });
    });
};


export const loadBoard = (boardId) => dispatch => {
  dispatch({ type: Types.SINGLE_BOARD_LOADING, payload: true });
  axios
    .get(`/boards/${boardId}/`, { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.SINGLE_BOARD_LOADED, payload: res.data });
      dispatch({ type: Types.SINGLE_BOARD_LOADING, payload: false });
    })
    .catch((error) => {
      dispatch({
        type: Types.SINGLE_BOARD_LOAD_ERROR,
        payload: error?.response?.data || {},
      });
      dispatch({ type: Types.SINGLE_BOARD_LOADING, payload: false });
    });
}
