import React from 'react'
import { createUUID } from '../../utils'
import Modal from 'react-modal';
import HtmlParser from 'html-react-parser';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#000000",
    maxWidth: "800px",
    width: "100%",
    maxHeight: "600px"
  },
};


const Comments = ({ onClose, comments }) => {

  return (
    <Modal ariaHideApp={false} isOpen={true} style={customStyles} onRequestClose={onClose}>
      <div className="modal-example-header">
        <button type="button" style={{ color: "white" }} className="close" onClick={onClose}>×</button>
        <h1>Vrijwaring opstellen en opsturen</h1>
      </div>
      <div className="modal-example-body">
        {comments?.map(comment => (
          <div key={createUUID()} className="chat-console">
            <div className="chat-console-top">
              <div className="profile-img"><img src={comment.creator?.photo_thumb_small} alt="profile-img" /></div>
              <div className="name">{comment.creator?.name}<span className="visible">&nbsp;</span></div>
            </div>
            <div className="chat-console-body" style={{ marginBottom: '10px'}}>{HtmlParser(comment.body)}</div>
            {comment.replies?.map(reply => (
              <div key={createUUID()} className="chat-console">
                <div className="chat-console-top">
                  <div className="profile-img"><img src={reply.creator?.photo_thumb_small} alt="profile-img" /></div>
                  <div className="name">{reply.creator?.name}<span className="visible">&nbsp;</span></div>
                </div>
                <div className="chat-console-body">{HtmlParser(reply.body)}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  )

}


export default Comments