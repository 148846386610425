import * as Types from "../actions/actionTypes";

const initialState = {
  boards: [],
  loading: true,
  errors: {},
  board: {
    loading: true,
    errors: {},
    data: {},
  },
};

function boardReducer(state = initialState, action) {
  switch (action.type) {
    case Types.BOARDS_LOADED: {
      return {
        ...state,
        boards: action.payload,
        errors: {},
      };
    }
    case Types.BOARDS_LOADING: {
      return {
        ...state,
        loading: action.payload,
        errors: {},
      };
    }
    case Types.BOARDS_LOAD_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      };
    }
    case Types.SINGLE_BOARD_LOADING: {
      return {
        ...state,
        board: {
          ...state.board,
          loading: action.payload
        }
      }
    }
    case Types.SINGLE_BOARD_LOADED: {
      return {
        ...state,
        board: {
          ...state.board,
          errors: {},
          data: action.payload
        }
      }
    }
    case Types.SINGLE_BOARD_LOAD_ERROR: {
      return {
        ...state,
        board: {
          ...state.board,
          errors: action.payload,
        }
      }
    }
    default:
      return state;
  }
}

export default boardReducer;
