import React, { useEffect } from 'react'
import classes from './styles.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { loadBoards } from '../../store/actions/boardActions'
import FullScreenLoading from '../../components/Loader/FullScreenLoading'
import { setPageTitle } from '../../utils'

const Dashbaord = ({ history }) => {
  const dispatch = useDispatch()
  const board = useSelector(state => state.board)

  useEffect(() => {
    dispatch(loadBoards())
    setPageTitle("Dashboard")

    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.container}>
      {board.loading ? (
        <FullScreenLoading />
      ) : (
        <>
          {board.errors.length ? (
            <div className={classes.errorMessage}>
              Error loading boards. Please try again later.
            </div>
          ) : (
            <>
              {board.boards?.length > 0 ? (
                <div className={classes.gridContainer}>
                  {board.boards?.map(brd => (
                    <div
                      key={brd.board_id}
                      className={classes.gridContainer_item}
                      onClick={() => history.push(`/board/${brd.board_id}`)}
                    >
                      <div className={classes.gridContainer_item_boardName}>
                        {brd.board_name || "Board name N/A"}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={classes.errorMessage}>
                  You've no board assigned!
                </div>
              )}
            </>
          )}
        </>
      )}


    </div>
  )

}


export default Dashbaord