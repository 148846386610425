export function getHeaders(additional){
  const userToken = localStorage.getItem('userToken')

  let headers = {
    ...additional,
  }
  
  if (userToken) {
    headers["Authorization"] = `Token ${userToken}`
  }

  return headers
}


export function createUUID() {
  // used to generate a random UUID (for key props)
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
    return v.toString(16);
  });
}

export const setPageTitle = (title) => {
  const siteName = "Monday Page"
  if (title) {
    document.title = title + " - " + siteName
  } else {
    document.title = siteName
  }
  return true
}


export const makeRandomString = (length) => {
  var result           = '';
  var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}
