import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";

import LoginPage from "../pages/auth/LoginPage"
import NotFound from "../pages/Others/NotFound";
import AccessDenied from "../pages/Others/AccessDenied";
import Dashbaord from "../pages/Dashboard/Dashbaord";
import BoardPage from "../pages/Board/BoardPage";


const Routes = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={"/"}
        component={Dashbaord}
      />
      <PrivateRoute
        exact
        path={"/board/:boardId(\\d+)"}
        component={BoardPage}
      />
      
      {/* auth routes */}
      <GuestRoute
        exact
        path={"/login"}
        component={LoginPage}
      />

      <Route path={"/access-denied"} component={AccessDenied} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
